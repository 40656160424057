import {Component, Input} from '@angular/core';
import {FreeMB, OAServices} from "@core/enums";
import {SoftwareService} from "@core/services/software.service";

@Component({
    selector: 'app-feature-banner',
    templateUrl: './feature-banner.component.html',
    styleUrls: ['./feature-banner.component.scss']
})
export class FeatureBannerComponent {



    constructor() {
    }


    protected readonly FreeMB = FreeMB;
}
