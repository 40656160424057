import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {IOrder} from '@core/model';
import {IAccountOrdersResolved} from '@core/model/HTTP/Resolver';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AccountPanelOrdersResolver implements Resolve<IAccountOrdersResolved> {

  constructor(private baseAccountService: BaseAccountService, @Inject(PLATFORM_ID) private platformId: object) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAccountOrdersResolved> {
    if (!isPlatformBrowser(this.platformId))
      return of({
        orders: null,
        errorMessage: 'Not a browser'
      });

    return this.baseAccountService.fetchOrders().pipe(
      map((orders: IOrder[]) => ({orders} as IAccountOrdersResolved)),
      catchError(err => {
        return of({
          orders: null,
          errorMessage: err
        } as IAccountOrdersResolved);
      }));
  }
}
