import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdComponent} from '@modules/shared/ad/ad.component';


@NgModule({
  declarations: [AdComponent],
  imports: [
    CommonModule
  ],
  exports: [
    AdComponent
  ]
})
export class AdsModule {
}
