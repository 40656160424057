import {Pipe, PipeTransform} from '@angular/core';
import {OAServices} from '@core/enums';

@Pipe({
  name: 'orderServiceNamePipe'
})
export class OrderServiceNamePipe implements PipeTransform {

  transform(orderService: string): string {
    switch (orderService) {
      case OAServices.AutoSlicer:
        return 'AI Auto Slicer';

      case OAServices.SoundSeparator:
        return 'Stem Isolator';

      case OAServices.BatchNormalizer:
        return 'Normalizer';

      case OAServices.FileConverter:
        return 'File Converter';

      case OAServices.ComingSoon:
        return 'New tools coming soon...';
      default:
        return orderService;
    }

  }

}
