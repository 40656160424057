import {HttpClient, HttpContext} from '@angular/common/http';
import {isDevMode} from '@angular/core';
import {LocalStorageService} from '@core/services/local-storage';
import {of, throwError} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, startWith, tap, timeout} from 'rxjs/operators';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {IAccount, IAccountSettings, ICustomerPortalSessionLink, IOrder, IServiceOrder} from '@core/model';
import {environment} from '../../../../environments/environment';
import {NGX_LOADING_BAR_IGNORED} from '@ngx-loading-bar/http-client';
import {IServerOptions} from '@core/model/serverOptions';
import {LocalStorageKey, Time} from '@core/enums';

export class AccountService extends BaseAccountService {
  constructor(private http: HttpClient,
              protected localStorageService: LocalStorageService) {
    super(localStorageService);
  }


  /**
   * Updates the account settings
   * @param settings the setting to update
   */
  updateSettings(settings: IAccountSettings): Observable<IAccountSettings> {
    return this.http.put<IAccountSettings>(`${environment.urls.accounts.base}/${environment.urls.accounts.accounts}/${this.getAccount().customerId}/settings`, settings,
      {context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)}).pipe(
      timeout(30000),
      catchError(err => {
        return throwError(err);
      }));
  }

  /**
   * Requests the service-orders tied to the account which is signed in
   */
  fetchOrders(customerId?: string): Observable<IOrder[]> {
    return this.http.get<IOrder[]>(`${environment.urls.accounts.base}/${environment.urls.accounts.accounts}/${customerId ? customerId : this.getAccount().customerId}/orders`).pipe(
      timeout(30000),
      catchError((err) => {
        if (isDevMode()) {
          console.log('Error occurred while fetching orders');
          console.log(err);
        }

        return throwError(err);
      }));
  }


  fetchSettings(): Observable<IAccountSettings> {
    return this.http.get<IAccountSettings>(`${environment.urls.accounts.base}/${environment.urls.accounts.accounts}/${this.getAccount().customerId}/settings`).pipe(
      timeout(30000),
      catchError((err) => {
        if (isDevMode()) {
          console.log('Error occurred while fetching account settings');
          console.log(err);
        }
        return throwError(err);

      }));
  }


  /**
   * fetch the account data from the server
   */
  fetchAccount(customerId?: string): Observable<IAccount> {
    return this.http.get<IAccount>(`${environment.urls.accounts.base}/${environment.urls.accounts.accounts}/${customerId ? customerId : this.getAccount().customerId}`).pipe(
      timeout(30000),
      catchError((err => {
        return throwError(err);
      }))
    );

  }

  fetchServiceOrders(customerId?: string): Observable<IServiceOrder[]> {
    return this.http.get<IServiceOrder[]>(`${environment.urls.accounts.base}/${environment.urls.accounts.accounts}/${customerId ? customerId : this.getAccount().customerId}/serviceOrders`)
      .pipe(
        timeout(30000),
        catchError((err) => {
          if (isDevMode()) {
            console.log('Error occurred while fetching service-orders');
            console.log(err);
          }

          return throwError(err);
        }));
  }

  createPortalSession(): Observable<ICustomerPortalSessionLink> {
    return this.http.post<ICustomerPortalSessionLink>(`${environment.urls.store.base}/${environment.urls.store.portal}`,
      null).pipe(
      timeout(30000),
      catchError((err) => {
        if (isDevMode()) {
          console.log('Error occurred while creating customer portal session');
          console.log(err);
        }

        return throwError(err);
      }));
  }

  getServerOptions(): Observable<IServerOptions> {
    const options: string =
      this.localStorageService.getLocalStorageItem<string>(LocalStorageKey.ACCOUNT_SERVER_OPTIONS);

    if (isDevMode())
      console.log(options);

    if (!options)
      return this.http.get<IServerOptions>(`${environment.urls.accounts.base}/${environment.urls.accounts.options}`).pipe(
        startWith({maintenance: false} as IServerOptions),
        timeout(30000),
        tap(async receivedServerOptions => {
          if (isDevMode())
            console.log(receivedServerOptions);
          await this.localStorageService.saveLocalStorageItem(LocalStorageKey.ACCOUNT_SERVER_OPTIONS,
            JSON.stringify(receivedServerOptions), 5 * Time.Minute);
        }),
        catchError(err => {
          if (isDevMode())
            console.log(err);
          // Return a maintenance object if the request fails
          return of({maintenance: true} as IServerOptions);
        }));

    const accountServerOptions: IServerOptions = JSON.parse(options);
    return of(accountServerOptions);
  }

  deleteAccount(customerId: string): Observable<any> {
    return this.http.delete<any>(`${environment.urls.accounts.base}/${environment.urls.accounts.accounts}/${customerId}`).pipe(
      timeout(30000),
      catchError((err) => {
        if (isDevMode()) {
          console.log('Error occurred while deleting account');
          console.log(err);
        }

        return throwError(err);
      }));
  }

}

