import {Injectable, isDevMode} from '@angular/core';
import {LocalStorageService} from '@core/services/local-storage';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  IAccount,
  IAccountSettings,
  ICustomerPortalSessionLink,
  ICustomerSubscription,
  ICustomerSubscriptionItem,
  IOrder,
  IServiceOrder
} from '@core/model';
import {LocalStorageKey, Time} from '@core/enums';
import {IServerOptions} from '@core/model/serverOptions';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseAccountService {

  /**
   * Fired when the available credits changed
   */
  availableCreditsUpdated$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  protected constructor(protected localStorageService: LocalStorageService) {

  }

  /**
   * Saves the account to the local storage
   * @param account to be stored
   */
  saveAccount(account: IAccount): Promise<void> {
    try {
      return new Promise<void>(async (resolve) => {
        if (!account)
          return;

        // Extract claims for jwt
        const jsonData = this.decodeJwt(account.session);
        const podcastAccess = jsonData?.podcastaccess ?? false;

        if (account.session && account.session.length > 0)
          await this.localStorageService.saveLocalStorageItem(LocalStorageKey.SESSION, account.session, 1 * Time.Hour);

        await this.localStorageService.saveLocalStorageItem(LocalStorageKey.PODCAST_ACCESS, podcastAccess, 1 * Time.Hour);
        await this.localStorageService.saveLocalStorageItem(LocalStorageKey.ACCOUNT, account, 1 * Time.Hour);
        resolve();
      });
    } catch (ex) {
      if (isDevMode())
        console.log(ex);
    }
  }

  hasSubscription(account: IAccount): boolean {
    if (!account)
      return false;

    return !(!account.subscriptions || account.subscriptions.length === 0);

  }

  /**
   * Returns the stored session
   */
  getSession(): string {
    return this.localStorageService.getLocalStorageItem<string>(LocalStorageKey.SESSION);
  }

  /**
   * Requests all server options
   */
  abstract getServerOptions(): Observable<IServerOptions>;


  /**
   * Returns the stored account or null!
   */
  getAccount(): IAccount | null {
    const account: IAccount = this.localStorageService.getLocalStorageItem<IAccount>(LocalStorageKey.ACCOUNT);
    if (!account)
      return null;

    return account;
  }

  /**
   * Does the account has an all access subscription?
   */
  public getAllAccessSubscriptionItem(account: IAccount): ICustomerSubscriptionItem | null {
    if (!account.subscriptions)
      return null;

    for (const subscription of account.subscriptions)
      for (const subscriptionItem of subscription.items)
        if (subscriptionItem.productName === 'Standard' || subscriptionItem.productName === 'Basic' ||
          subscriptionItem.productName === 'Pro' || subscriptionItem.productName === 'Premium')
          return subscriptionItem;

    return null;
  }

  /**
   * Does the account has an all access subscription?
   */
  public getAllAccessSubscription(account: IAccount): ICustomerSubscription | null {
    if (!account || !account.subscriptions)
      return null;

    for (const subscription of account.subscriptions)
      for (const subscriptionItem of subscription.items)
        if (subscriptionItem.productName === 'Standard' || subscriptionItem.productName === 'Basic' ||
          subscriptionItem.productName === 'Pro' || subscriptionItem.productName === 'Premium')
          return subscription;

    return null;
  }

  /***
   * Decodes the jwt content and returns a json
   * @param token
   */
  public decodeJwt(token: string) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    } catch (ex) {
      return null;
    }

  }

  /**
   * Updates the account settings
   * @param settings the setting to update
   */
  abstract updateSettings(settings: IAccountSettings): Observable<IAccountSettings>;

  /**
   * Requests the orders tied to the account which is signed in
   */
  abstract fetchOrders(customerId?: string): Observable<IOrder[]>;

  /**
   * Requests the service orders tied to the account
   */
  abstract fetchServiceOrders(customerId?: string): Observable<IServiceOrder[]>;


  /**
   * Returns the user settings
   */
  abstract fetchSettings(): Observable<IAccountSettings>;

  /**
   * fetches the account data from the server
   */
  abstract fetchAccount(customerId?: string): Observable<IAccount>;

  /**
   * Creates a portal session and return the link
   */
  abstract createPortalSession(): Observable<ICustomerPortalSessionLink>;

  /**
   * Deletes the account
   * @param customerId
   */
  abstract deleteAccount(customerId: string): Observable<any>;

}
