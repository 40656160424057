import {Injectable, isDevMode} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {of, Subject} from 'rxjs';
import {IStepperParameters} from '@core/model';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor() {
  }

  /**
   * When the Service is unavailable
   */
  public serviceUnavailable: Subject<boolean> = new Subject<boolean>();

  /** Triggered when a customer checks the checkbox */
  public agreedToTerms$: Subject<boolean> = new Subject<boolean>();
  public agreedToTermsReset$: Subject<void> = new Subject<void>();


  /** Is being fired after scroll to top link is pressed on a page */
  public scrollToTop: Subject<void> = new Subject<void>();

  /** Display the blocking loading overlay */
  public showLoadingOverlay: Subject<boolean> = new Subject<boolean>();


  public handleHttpError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (isDevMode()) {
        console.log(`${operation}: failed`);
        console.log(`failed: ${error.message}`);
      }

      return of(result as T);
    };
  }
}
