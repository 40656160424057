<!-- Footer -->
<footer class="text-center mt-4">
  <!-- Grid container -->
  <div class="container p-4">
    <!-- Section: Social media -->
    <section class="mb-4" aria-label="Social media links">
      <!-- LinkedIn -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon" href="https://www.linkedin.com/company/origin-audio/"
         target="_blank" role="button" aria-label="Visit Origin Audio's LinkedIn page">
        <i class="fab fa-linkedin-in"></i>
      </a>

      <!-- Twitter -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon" href="https://twitter.com/OAMusicAI"
         target="_blank" role="button" aria-label="Visit Origin Audio's Twitter page">
        <i class="fab fa-twitter"></i>
      </a>

      <!-- Discord -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon" href="https://discord.gg/tEFparsvJ4"
         target="_blank" role="button" aria-label="Join Origin Audio's Discord server">
        <i class="fab fa-discord"></i>
      </a>

      <!-- Instagram -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon" href="https://www.instagram.com/origin_audio_llc/"
         role="button" target="_blank" aria-label="Visit Origin Audio's Instagram page">
        <i class="fab fa-instagram"></i>
      </a>

      <!-- Medium -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon" href="https://originaudio.medium.com/"
         target="_blank" role="button" aria-label="Visit Origin Audio's Medium blog">
        <i class="fab fa-medium-m"></i>
      </a>

      <!-- Email -->
      <a class="btn btn-outline-light btn-floating m-1 oa-icon"
         href="mailto:contact@originaudio.net?subject=I want to learn more about Origin Audio"
         role="button" aria-label="Contact Origin Audio via email">
        <i class="fas fa-envelope-square"></i>
      </a>
    </section>
    <!-- Section: Social media -->

    <!-- Section: Newsletter Form -->
    <section *ngIf="!submitted" aria-labelledby="newsletter-heading">
      <h2 id="newsletter-heading" class="sr-only">Sign up for our newsletter</h2>
      <form [formGroup]="newsletterForm" #newsletter="ngForm" (ngSubmit)="onSubmit()" *ngIf="isBrowser">
        <div class="row d-flex justify-content-center mt-5 mb-4">
          <!-- Form title -->
          <div class="col-auto">
            <div class="mat-h2">
              Sign up for our newsletter
              <span><i class="fas fa-arrow-right"></i></span>
            </div>
          </div>
          <!-- Email input -->
          <div class="col-md-5 col-12">
            <div class="form-outline form-white mb-4">
              <label for="emailInput" class="sr-only">Email address</label>
              <input type="email" id="emailInput" class="form-control" formControlName="email"
                     placeholder="Email address" aria-describedby="emailHelp">
              <span id="emailHelp" class="form-text">Please provide a valid email address</span>
              <label class="form-label text-danger" for="emailInput"
                     *ngIf="email.invalid && (email.dirty || email.touched) && (email.errors?.email || email.errors?.pattern)">
                Please provide a valid email address
              </label>
            </div>
          </div>
          <!-- Submit button -->
          <div class="col-auto">
            <button type="submit" class="btn btn-outline-light mb-4"
                    [disabled]="processing || !newsletterForm.valid" aria-label="Subscribe to the newsletter">
              Subscribe
            </button>
          </div>
        </div>
      </form>
    </section>

    <section *ngIf="submitted" aria-live="polite">
      <h1>Thank you for subscribing!</h1>
    </section>
    <!-- Section: Newsletter Form -->

    <!-- Section: Links -->
    <section aria-label="Footer links">
      <div class="row justify-content-center">
        <!-- Grid column: Use & Learn -->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Use & Learn</h5>
          <ul class="list-unstyled mb-0">
            <li><a routerLink="/pricing" class="text-black">Pricing</a></li>
            <li><a routerLink="{{autoSlicer.route}}" class="text-black">{{autoSlicer.name | orderServiceNamePipe}}</a></li>
            <li><a routerLink="{{batchNormalizer.route}}" class="text-black">{{batchNormalizer.name | orderServiceNamePipe}}</a></li>
            <li><a routerLink="{{soundSeparator.route}}" class="text-black">{{soundSeparator.name | orderServiceNamePipe}}</a></li>
            <li><a routerLink="{{fileConverter.route}}" class="text-black">{{fileConverter.name | orderServiceNamePipe}}</a></li>
          </ul>
        </div>
        <!-- Grid column: Education -->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Education</h5>
          <ul class="list-unstyled mb-0">
            <li><a routerLink="/education/students" class="text-black">Students</a></li>
          </ul>
        </div>
        <!-- Grid column: Legal -->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Legal</h5>
          <ul class="list-unstyled mb-0">
            <li><a href="https://data.originaudio.net/documents/Privacy%20Policy.pdf" target="_blank" class="text-black">Privacy Policy</a></li>
            <li><a href="https://data.originaudio.net/documents/Terms%20of%20Service.pdf" target="_blank" class="text-black">Terms of Service</a></li>
          </ul>
        </div>
        <!-- Grid column: Company -->
        <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Company</h5>
          <ul class="list-unstyled mb-0">
            <li><a href="mailto:contact@originaudio.net" class="text-black">Contact</a></li>
            <li><a routerLink="about" class="text-black">About</a></li>
            <li><a href="https://originaudio.medium.com" target="_blank" class="text-black">Blog</a></li>
            <li><a routerLink="about" class="text-black">ESG</a></li>
          </ul>
        </div>
      </div>
    </section>
    <!-- Section: Links -->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
    © 2021 - {{currentYear}} <a class="text-black" href="https://www.originaudio.net/">Origin Audio</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
