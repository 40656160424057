import {AfterViewInit, Component, Inject, Input, isDevMode, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss']
})
export class AdComponent implements OnInit, AfterViewInit {

  @Input() adSlot;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) return;

    setTimeout(() => {
      try {
        (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
      } catch (e) {
        if (isDevMode())
          console.error(e);
      }
    }, 1000);
  }
}
