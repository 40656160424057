import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SoftwareFaqComponent} from '@modules/shared/shared-software-module/software-faq/software-faq.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {RouterModule} from '@angular/router';
import {SoftwareToolPerksComponent} from '@modules/shared/shared-software-module/software-tool-perks/software-tool-perks.component';
import {TestimonialCardComponent} from '@modules/shared/shared-software-module/testimonial-card/testimonial-card.component';
import {DirectivesModule} from '@core/directives/directives.module';
import {ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '@core/pipes/pipes.module';
import { FeatureCardsComponent } from './feature-cards/feature-cards.component';
import {ToolHeaderComponent} from '@modules/shared/shared-software-module/tool-header/tool-header.component';
import { ServiceSelectorComponent } from '@modules/shared/shared-software-module/service-selector/service-selector.component';
import {AdsModule} from '@modules/shared/ad/ads.module';


@NgModule({
  declarations: [
    SoftwareFaqComponent,
    SoftwareToolPerksComponent,
    TestimonialCardComponent,
    FeatureCardsComponent,
    ToolHeaderComponent,
    ServiceSelectorComponent
  ],
    imports: [
        CommonModule,
        MatExpansionModule,
        RouterModule,
        DirectivesModule,
        ReactiveFormsModule,
        PipesModule,
        AdsModule,
    ],
  exports: [
    SoftwareFaqComponent,
    SoftwareToolPerksComponent,
    TestimonialCardComponent,
    FeatureCardsComponent,
    ToolHeaderComponent,
    ServiceSelectorComponent,
  ]
})
export class SharedSoftwareModule {
}
