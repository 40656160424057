import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {IAccountSettings} from '@core/model';
import {isPlatformBrowser} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class AccountPanelSettingsResolver implements Resolve<IAccountSettings> {

  constructor(private baseAccountService: BaseAccountService, @Inject(PLATFORM_ID) private platformId: object) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAccountSettings> {
    if (!isPlatformBrowser(this.platformId))
      return null;
    return this.baseAccountService.fetchSettings();
  }
}
