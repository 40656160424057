<div class="container" role="region" aria-label="Advertisement">
  <div class="col-sm-12">
    <ins class="adsbygoogle"
         style="display:block; text-align:center;"
         data-ad-layout="in-article"
         data-ad-format="fluid"
         data-ad-client="ca-pub-1523673877516620"
         [attr.data-ad-slot]="adSlot"
         aria-label="Google Ad"></ins>
  </div>
</div>
