import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {IAccount} from '@core/model';
import {RoleIds} from '@core/enums';
import {UrlService} from '@core/services/urlService';
import {isPlatformBrowser} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private baseAccountService: BaseAccountService,
    private urlService: UrlService,
    @Inject(PLATFORM_ID) private platformId: object) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!isPlatformBrowser(this.platformId))
      return false;


    const account: IAccount = this.baseAccountService.getAccount();
    if (account && account.access >= RoleIds.Moderator)
      return true;

    this.urlService.redirectUrl = state.url;
    this.router.navigate(['/authentication/signin']).then();
    return false;
  }

}
