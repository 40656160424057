import {Inject, Injectable, isDevMode, OnDestroy, PLATFORM_ID} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';

/**
 * ToastService
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService implements OnDestroy {

  /**
   * Subject to trigger toast messages
   */
  public showToastSubscription: Subject<string> = new Subject<string>();

  private subscriptions$: Subscription[] = [];
  private readonly isBrowser: boolean;

  /**
   * ToastService Constructor
   * @param platformId - Injected platform identifier
   */
  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    // Determine if the current platform is the browser
    this.isBrowser = isPlatformBrowser(this.platformId);

    if (this.isBrowser)
      // Subscribe to showToastSubscription only in the browser
      this.subscriptions$.push(this.showToastSubscription.subscribe((message: string) => {
        this.showToast(message);
      }));
  }

  /**
   * Clean up subscriptions to prevent memory leaks
   */
  ngOnDestroy() {
    this.subscriptions$.forEach(x => x.unsubscribe());
  }

  /**
   * Displays a toast at the bottom of the Page
   * @param message - The message to display in the toast
   */
  showToast(message: string): void {
    if (!this.isBrowser)
      // Do nothing if not running in the browser
      return;


    const snackbarElement = document.getElementById('snackbar');
    if (snackbarElement) {
      snackbarElement.innerHTML = message;
      snackbarElement.className = 'snackbar snackbar-show';
      setTimeout(() => {
        snackbarElement.className = 'snackbar';
      }, 3000);
      // tslint:disable-next-line:curly
    } else {
      if (isDevMode())
        console.warn('Snackbar element with ID "snackbar" not found.');
    }
  }
}
