import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {IAccountServiceOrderResolved} from '@core/model/HTTP/Resolver';
import {IServiceOrder} from '@core/model';
import {of} from 'rxjs';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AccountPanelServiceOrderResolver implements Resolve<IAccountServiceOrderResolved> {

  constructor(private baseAccountService: BaseAccountService, private router: Router,
              @Inject(PLATFORM_ID) private platformId: object) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAccountServiceOrderResolved> {
    if (!isPlatformBrowser(this.platformId))
      return of({
        serviceOrder: null,
        error: 'Not a browser'
      });


    const selectedOrderId: string = route.paramMap.get('orderId');
    const account = this.baseAccountService.getAccount();
    let selectedOrder: IServiceOrder = null;
    for (const order of account.serviceOrders)
      if (order.id === selectedOrderId)
        selectedOrder = order;

    if (!selectedOrder) {
      this.router.navigate(['/account/panel/service-orders']);
      return of(null);
    }


    const selectedOrderResolved: IAccountServiceOrderResolved = {
      serviceOrder: selectedOrder,
      error: null,
    };

    return of(selectedOrderResolved);

  }
}
