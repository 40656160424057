import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {BaseAccountService} from '@core/abstract/account.abtract';
import {IAccount} from '@core/model';
import {IAccountResolved} from '@core/model/HTTP/Resolver/account';
import {catchError, map} from 'rxjs/operators';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AccountPanelResolver implements Resolve<IAccountResolved> {

  constructor(private baseAccountService: BaseAccountService, @Inject(PLATFORM_ID) private platformId: object) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAccountResolved> {
    if (!isPlatformBrowser(this.platformId))
      return of({
        account: null,
        error: 'Not a browser'
      });

    if (!this.baseAccountService.getAccount())
      return of({
        account: null,
        error: 'Not signed in'
      });

    return this.baseAccountService.fetchAccount().pipe(
      map((account: IAccount) => ({account} as IAccountResolved)),
      catchError(err => {
        return of({
          account: null,
          error: err
        } as IAccountResolved);
      })
    );
  }
}
